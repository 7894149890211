import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';

interface ISeo {
  title: string | undefined;
  description: string | undefined | null;
  url?: string;
  image?: string;
  imageTitle?: string | null;
  canonical?: string;
  video?: string;
  noIndexActivated?: boolean;
}

const defaultSeoImage: string =
  'https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg';

export const Seo: React.FC<ISeo> = ({
  title,
  description,
  url,
  image,
  imageTitle,
  canonical,
  video,
  noIndexActivated
}) => {
  let location = useLocation();
  let URL: string = `https://privatmegleren.no${location.pathname}`;

  return (
    <Helmet>
      <title>{title}</title>
      {noIndexActivated ? <meta name="robots" content="noindex" /> : ''}
      <meta name="description" content={description} />
      <meta name="twitter:site" content="@PRIVAT_megleren" />
      <meta name="twitter:creator" content="@PRIVAT_megleren" />
      <meta name="twitter:card" content="" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />

      <meta
        name="twitter:image:src"
        content={image ? image : defaultSeoImage}
      />
      <meta property="og:title" content={title} />
      {!video ? <meta property="og:type" content="website" /> : ''}
      <meta property="og:url" content={URL} />
      <meta property="og:image" content={image ? image : defaultSeoImage} />
      <meta property="og:image:alt" content={imageTitle} />

      {video ? <meta property="og:video:url" content={video} /> : ''}
      {video ? <meta property="og:video:secure_url" content={video} /> : ''}
      {video ? <meta property="og:video:width" content={'398'} /> : ''}
      {video ? <meta property="og:video:height" content={'264'} /> : ''}
      {video ? <meta property="og:type" content="video" /> : ''}
      {video ? <meta property="og:video" content={video} /> : ''}

      {video ? (
        <meta
          property="og:video:type"
          content="application/x-shockwave-flash"
        />
      ) : (
        ''
      )}
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="PrivatMegleren" />
      <meta itemProp="name" content={title} />
      <meta itemProp="image" content={image ? image : defaultSeoImage} />
      <meta name="p:domain_verify" content="f665a9d2e04bdc20ecfd4d18cdb87987" />
      <link rel="canonical" href={URL} />
    </Helmet>
  );
};
