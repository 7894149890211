import styled from 'styled-components';
import { animated } from 'react-spring';

export const Form = styled.form`
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  float: left;
  margin-bottom: 20px;

  @media all and (max-width: 800px) {
    flex-flow: column;
  }
`;

export const FormColumn = styled.div`
  width: 100%;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  @media all and (max-width: 800px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const FormFieldsWrapper = styled.div`
  width: 100%;
  min-height: 400px;
`;

export const FormWrapper = styled(animated.div)`
  .form-text {
    max-width: 550px;
    margin: 0 auto;
  }
`;
