import fetch from 'isomorphic-unfetch';
import { ENV_CONFIG } from '../__config__/env';

import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink
} from '@apollo/client';

import { onError } from '@apollo/client/link/error';

import '../../client/__types__/window';

let httpLink = new HttpLink({
  uri: ENV_CONFIG.RT_CLUSTER_GRAPH,
  fetch: fetch,
  headers: {
    'x-client-id': 'privatmegleren',
    ssr: true
  },
  credentials: 'include'
});
let errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, extensions, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

let cache = new InMemoryCache();
if (typeof window !== 'undefined') {
  cache.restore(window.__APOLLO_STATE__);
}

const client = new ApolloClient({
  name: 'privatmegleren',
  cache,
  link: ApolloLink.from([errorLink, httpLink]),
  ssrForceFetchDelay: 100
});

export { client };
