import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import { loadableReady } from '@loadable/component';

import { client } from '../common/__graphql__/client';
import { App } from '../common/App';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';

const Application = () => (
  <HelmetProvider>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </ApolloProvider>
  </HelmetProvider>
);

loadableReady(() => {
  ReactDOM.render(<Application />, document.getElementById('root'));
});
