import * as React from 'react';
import styled from 'styled-components';

type InputType =
  | 'text'
  | 'tel'
  | 'email'
  | 'password'
  | 'checkbox'
  | 'textarea'
  | 'submit'
  | undefined
  | string;

export interface IInput {
  type?: InputType;
  name?: string;
  label?: string;
  labelAfter?: string;
  placeholder?: string;
  value?: string | number;
  error?: boolean;
  forwardRef?: any;
  required?: any;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  className?: string;
  style?: any;
}

export const Input: React.FC<IInput> = React.memo(
  React.forwardRef((props, ref) => {
    return (
      <StyledInputWrapper>
        {props.label ? (
          <label className={props.className}>
            {props.label}{' '}
            {props.labelAfter ? <span>{props.labelAfter}</span> : null}
          </label>
        ) : null}
        <StyledInput
          style={props.style}
          ref={ref}
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          error={props.error}
          tabIndex={0}
          required={props.required}
        />
      </StyledInputWrapper>
    );
  })
);

const StyledInputWrapper = styled.div`
  width: 100%;

  label {
    display: block;
    font-size: 1rem;
    color: ${(props) => props.theme.input.label};
    margin-bottom: 10px;

    span {
      color: ${(props) => props.theme.input.labelAfter};
    }
  }
`;

const StyledInput: any = styled.input`
  // Convert to grid
  display: block;
  padding: 15px;
  width: 100%;
  background: ${(props: any) => props.theme.input.background};
  font-size: ${(props) => props.theme.input.font.size};
  font-weight: 400;
  color: white;
  border: ${(props: any) =>
    props.error
      ? `solid 2px ${props.theme.input.error}`
      : 'solid 2px transparent'};
  outline: none;
  border-radius: 0;

  &[type='submit'] {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: ${(props: any) => props.theme.input.placeholder};
  }
`;
