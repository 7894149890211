import * as React from 'react';
import styled from 'styled-components';

type IPosition = 'left' | 'center' | 'right';
interface IButtonGroup {
  style?: any;
  className?: string;
  position?: IPosition;
}

export const ButtonGroup: React.FC<IButtonGroup> = ({
  style,
  className,
  position,
  children
}) => {
  return (
    <StyledButtonGroup position={position} className={className} style={style}>
      {children}
    </StyledButtonGroup>
  );
};

const StyledButtonGroup: any = styled.div`
  display: flex;
  width: 100%;

  ${(props: any) =>
    props.position === 'left' ? 'justify-content: flex-start;' : ''}
  ${(props: any) =>
    props.position === 'center' ? 'justify-content: center;' : ''}
  ${(props: any) =>
    props.position === 'right' ? 'justify-content: flex-end;' : ''}

  &.space-bottom {
    padding-bottom: 1.875rem;
  }

  &.space-top-bottom {
    padding: 1.25rem 0;
  }

  &.wrap-items {
    flex-flow: row wrap;
  }

  &.center {
    justify-content: center;
  }

  &.row {
    flex-flow: row;
  }

  &.row-wrap {
    flex-flow: row wrap;

    @media all and (max-width: 600px) {
      justify-content: center;
      flex-flow: column;
      align-items: center;
      div {
        margin-right: 0 !important;
      }
    }
  }

  @media all and (max-width: ${(props) => props.theme.mediaSizes.medium}px) {
    flex-flow: column;
    &.reverse {
      flex-flow: column-reverse;
    }
    .privacy-link-mobile {
      justify-content: center;
      padding: 25px !important;
    }
  }
`;
