import * as React from 'react';
import styled from 'styled-components';
import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import { Seo } from '../../../../utils/seo/Index';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';
import { Container } from '../../../layout/Container';
import { Button } from '../../../ui/button/Button';
import { ButtonGroup } from '../../../ui/button/ButtonGroup';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { useLocation, useParams } from 'react-router';
import { useQuiz } from '../../../../utils/hooks/useQuiz';
import { Section } from '../../../layout/Section';
import { animated, useSpring } from 'react-spring';
import NoMatch from '../../404Old';

import { Question } from './question';
import { FormSection } from './form';

//GraphQL
import { useQuery, useLazyQuery } from '@apollo/client';
import {
  QUIZ_PAGE,
  QUIZ_QUESTIONS
} from '../../../../../client/__graphql__/queries/quiz';
import Helmet from 'react-helmet';

export interface QuestionItem {
  id: number;
  locked: boolean;
  selected: boolean;
  question: string;
  options: QOptionItem[];
}

export interface QOptionItem {
  id: number;
  letter: string;
  label: string;
  correct: boolean;
  selected: boolean;
}

export const QuizTemplate_V3: React.FC = () => {
  const location = useLocation();
  const params = useParams<{ area: string; urltag: string }>();

  const [showRules, setShowRules] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [noMatch, setNoMatch] = React.useState(false);
  const [items, setQuestions] = React.useState<any[]>([]);
  const thanksRef = React.useRef<any>(null);
  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  const { data: pData, loading: pLoading } = useQuery(QUIZ_PAGE, {
    skip: !params?.urltag && !params?.area,
    fetchPolicy: 'network-only',
    variables: { input: { urltag: params?.urltag } },
    onCompleted: (data: any) => {
      setLoading(false);
      if (data?.getQuizPage?.id > 0) {
        getQuestions();
      } else {
        setNoMatch(true);
      }
    }
  });

  const [
    getQuizQuestions,
    { data: qsdata, error: qsError, loading: qsLoading }
  ] = useLazyQuery(QUIZ_QUESTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (data: any) => {
      if (data && data?.getQuizQuestions?.length > 0) {
        const arr: any[] = [];

        data?.getQuizQuestions.map((entry: any, index: any) => {
          if (entry?.text && entry?.options?.length > 0) {
            const q: QuestionItem = {
              id: index + 1,
              locked: false,
              selected: false,
              question: entry?.text,
              options: []
            };

            entry?.options?.map((opt: any) => {
              q.options.push({
                id: opt.id,
                correct: opt.isCorrect,
                label: opt.text,
                letter: opt.letter,
                selected: false
              });
            });

            arr.push(q);
          }
        });

        setQuestions(arr);
      }
    }
  });

  const getQuestions = () => {
    getQuizQuestions({
      variables: {
        input: { id: pData?.getQuizPage?.id }
      }
    });
  };

  const {
    isStarted,
    isPosted,
    isDone,
    start,
    prev,
    next,
    lock,
    done,
    posted,
    cancel,
    selectAnswer,
    currentNumber,
    correctNumber,
    questions,
    totalQuestions,
    currentQuestion
  } = useQuiz({
    storeName: params?.urltag && params?.area,
    questions: items,
    onReset: () => {
      getQuestions();
    }
  });

  React.useLayoutEffect(() => {
    if (isPosted) {
      const currentScroll = window.scrollY || window.pageYOffset;
      window.scrollTo({ top: currentScroll - 150 });
    }
  }, [isPosted]);

  const animatedProps = useSpring({
    from: { opacity: 0, display: 'none' },
    to: { opacity: isPosted ? 1 : 0, display: isPosted ? 'block' : 'none' }
  });

  if (
    noMatch ||
    (params.area === 'fjell' && params.urltag === 'loypa-til-solgt-2') ||
    (params.area === 'sjo' && params.urltag === 'loypa-til-solgt')
  ) {
    return <NoMatch />;
  }

  return (
    <QuizPageWrapper>
      {params.area === 'fjell' && params.urltag === 'loypa-til-solgt' && (
        <Helmet>
          <script src="https://embed.vev.page/v1/WhJ1pnZyKD/pakFvF1vSua"></script>
        </Helmet>
      )}
      {params.area === 'sjo' && params.urltag === 'loypa-til-solgt-2' && (
        <Helmet>
          <script src="https://embed.vev.page/v1/dZ5MFuE5Hz/pakFvF1vSua"></script>
        </Helmet>
      )}
      <Seo
        title={pData?.getQuizPage?.seo?.title}
        description={pData?.getQuizPage?.seo?.content}
        image={pData?.getQuizPage?.seo?.image}
        url={location.pathname}
      />
      <Container
        /*         ref={containerRef} */
        style={{ ...pageFadeIn, paddingTop: '150px', paddingBottom: '100px' }}
      >
        {pData?.getQuizPage?.active ? (
          <>
            <Section className="form-section" style={{ paddingTop: 0 }}>
              <Heading tag="h1" center={true}>
                {pData?.getQuizPage?.pageInit?.title}
              </Heading>
              {pData?.getQuizPage?.pageInit?.ingress ? (
                <Heading tag="h2" center={true}>
                  {pData?.getQuizPage?.pageInit?.ingress}
                </Heading>
              ) : null}

              <Paragraph center={true}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: pData?.getQuizPage?.pageInit?.content
                  }}
                />
              </Paragraph>
              {pData?.getQuizPage?.rules ? (
                <>
                  <ButtonGroup
                    position="center"
                    style={{ textAlign: 'center' }}
                  >
                    <Button
                      type="tertiary"
                      colorTheme="gold"
                      onClick={() => setShowRules((prev) => !prev)}
                    >
                      {showRules
                        ? 'Lukk konkurranseregler'
                        : 'Se konkurranseregler'}
                    </Button>
                  </ButtonGroup>
                  {showRules ? (
                    <div
                      style={{ marginTop: '20px', textAlign: 'center' }}
                      dangerouslySetInnerHTML={{
                        __html: pData?.getQuizPage?.rules
                      }}
                    />
                  ) : null}
                </>
              ) : null}
            </Section>
            {!isStarted ? (
              <>
                <ButtonGroup className="center">
                  <Button
                    type="primary"
                    colorTheme="gold"
                    onClick={() => {
                      start();
                    }}
                  >
                    Start quiz
                  </Button>
                </ButtonGroup>
              </>
            ) : null}
            <div ref={thanksRef} />
            <Section className="form-section" style={animatedProps}>
              <Heading tag="h2" center={true}>
                {pData?.getQuizPage?.pageThankyou?.title}
              </Heading>
              <Paragraph center={true}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: pData?.getQuizPage?.pageThankyou?.content
                  }}
                />
              </Paragraph>
            </Section>
            {isDone && !isPosted ? (
              <Section className="form-section" style={{ paddingTop: 0 }}>
                <Heading tag="h2" center={true}>
                  Du fikk {correctNumber} av {totalQuestions} riktige
                </Heading>
                <ButtonGridGroup style={{ marginBottom: '50px' }}>
                  <Button
                    type="tertiary"
                    colorTheme="gold"
                    onClick={() => {
                      if (typeof cancel === 'function') {
                        cancel();
                      }
                    }}
                  >
                    Jeg vil prøve en gang til
                  </Button>
                </ButtonGridGroup>
                <Heading tag="h2" center={true}>
                  Send inn dine svar
                </Heading>
                <FormSection
                  questions={questions}
                  post={posted}
                  mailType={pData?.getQuizPage?.mailType}
                  formZipcode={pData?.getQuizPage?.formZipcode}
                  privacyPolicy={pData?.getQuizPage?.privacyPolicy}
                />
              </Section>
            ) : null}
            {!isDone && isStarted && !isPosted ? (
              <Question
                loading={loading}
                totalQuestions={totalQuestions}
                currentQuestion={currentNumber}
                item={currentQuestion}
                prev={prev}
                next={next}
                lock={lock}
                done={done}
                cancel={cancel}
                select={selectAnswer}
              />
            ) : null}
          </>
        ) : (
          <>
            <Heading tag="h1" center={true}>
              {pData?.getQuizPage?.pageEnd?.title}
            </Heading>
            <Heading tag="h2" center={true}>
              {pData?.getQuizPage?.pageEnd?.ingress}
            </Heading>
            <Section className="form-section" style={{ paddingTop: 0 }}>
              <Paragraph center={true}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: pData?.getQuizPage?.pageEnd?.content
                  }}
                />
              </Paragraph>
            </Section>
          </>
        )}
      </Container>
      <BackgroundLoad
        className={
          params.urltag === 'st-haugen' || params.urltag === 'jaeren'
            ? 'background-contain'
            : ''
        }
        hideGradient={
          params.urltag === 'st-haugen' || params.urltag === 'jaeren'
            ? true
            : false
        }
        opacity={0.3}
        showOnMobile={true}
        url={
          pData?.getQuizPage?.active
            ? pData?.getQuizPage?.pageInit?.image
            : pData?.getQuizPage?.pageEnd?.image
        }
      />
    </QuizPageWrapper>
  );
};

const QuizPageWrapper = styled.div`
  width: 100%;
  min-height: 700px;

  .background-contain {
    height: 790px;
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

export const ButtonGridGroup = styled.div`
  display: grid;
  grid-template-columns: max-content;
  justify-content: center;

  &.col-2 {
    grid-template-columns: max-content max-content;
    grid-column-gap: 20px;

    @media all and (max-width: 320px) {
      grid-template-columns: max-content;
      grid-column-gap: 0;
      grid-row-gap: 20px;
    }
  }
`;

const Top = styled(animated.div)`
  position: relative;
  width: 100%;
  margin-top: 66px;
  margin-bottom: 50px;

  .content {
    display: flex;
    flex-flow: column;
    padding: 200px 0 0 0;
    position: absolute;
    width: 100%;
    display: flex;
    z-index: 1;

    h1 {
      margin-bottom: 0;
    }

    @media all and (max-width: 600px) {
      padding: 100px 0 0 0;
    }
  }

  img {
    width: 100%;
    object-fit: cover;
    min-height: 600px;
    max-height: 800px;

    @media all and (max-width: 600px) {
      object-fit: cover;
      min-height: 400px !important;
    }
  }
`;
