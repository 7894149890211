import { lighten } from 'polished';

export const colors = {
  primary3: '#171719',
  primary3Light: lighten(0.05, '#171719'),
  primary3LightHover: lighten(0.1, '#171719'),
  gold: {
    primary: '#E7CFA7'
  },
  grey: {
    darkest: '#181819',
    dark: '#242525'
  }
};
