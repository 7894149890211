import * as React from 'react';
import qs from 'querystring';
import styled from 'styled-components';
import { Container } from '../layout/Container';
import { useFadeTransition } from '../../utils/hooks/useFadeTransition';
import { Heading } from '../ui/text/Heading';
import { Paragraph } from '../ui/text/Paragraph';
import { BackgroundLoad } from '../layout/BackgroundLoad';
import { Route, useLocation } from 'react-router-dom';
import { Seo } from '../../utils/seo/Index';

const Status: React.FC<{ code: number; location: any }> = ({
  code,
  location,
  children,
}) => {
  return (
    <Route
      render={({ staticContext }: any) => {
        if (staticContext) {
          staticContext.status = code;
          staticContext.pathname = location?.pathname
            ? location.pathname
            : null;
        }
        return children;
      }}
    />
  );
};

const NoMatch: React.FC<any> = () => {
  const location = useLocation();

  const queryString = location.search ? qs.parse(location.search) : null;

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true,
  });

  return (
    <>
      <Seo
        title="Finner ikke siden - PrivatMegleren"
        description=""
        url={location.pathname}
      />
      <Status code={404} location={location}>
        <Container
          style={{
            paddingTop: '150px',
            minHeight: '800px',
            ...pageFadeIn,
          }}
        >
          <Heading tag="h1" center={true}>
            Siden finnes ikke
          </Heading>
          <InputWrapper>
            <Label>Hva søker du etter?</Label>
            <OldInput />
          </InputWrapper>

          <Paragraph center={true}>Feilkode: 404</Paragraph>
          <Paragraph center={true}>Kontrollér at adressen er riktig:</Paragraph>
          <Paragraph center={true}>
            https://privatmegleren.no
            {queryString && queryString['?url']
              ? queryString['?url']
              : location?.pathname}
          </Paragraph>
          <Paragraph center={true}>
            Gå til forsiden
            <a href="https://privatmegleren.no">(www.privatmegleren.no)</a>
          </Paragraph>
          <BackgroundLoad
            opacity={0.5}
            position="center -175px"
            url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
          />
        </Container>
      </Status>
    </>
  );
};

export default NoMatch;

const OldInput = () => {
  const [value, setValue] = React.useState('');
  return (
    <StyledInputWrapper>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          window.location.href = `https://privatmegleren.no/?q=${encodeURIComponent(
            value
          )}`;
        }}
      >
        <StyledOldInput
          placeholder="Søk navn, område eller fritekst"
          onChange={(e) => setValue(e.target.value)}
        />
        <SearchButton>Søk</SearchButton>
      </form>
    </StyledInputWrapper>
  );
};

const StyledInputWrapper = styled.div`
  width: 100%;

  form {
    display: flex;
    flex: 1 1 100%;
    width: 100%;
  }
`;

const StyledOldInput = styled.input`
  flex: 0 1 535px;
  position: relative;
  z-index: 50;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  font-size: 1.5rem;
  background: transparent;
  border: 0;
  padding: 0 18px;
  color: #fff;
  padding-top: 0;
  background: #17181a;
  margin-right: 10px;

  &::placeholder {
    color: white;
  }

  @media all and (max-width: 960px) {
    font-size: 1rem;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media all and (max-width: 960px) {
    flex-flow: column;
  }
`;

const Label = styled.p`
  flex: 0 0 180px;
  font-size: 18px;

  @media all and (max-width: 960px) {
    flex: auto;
  }
`;

const SearchButton = styled.button`
  flex: 1 0 150px;
  min-height: 60px;
  font-size: 1.5rem;
  padding: 3px 0 0;
  font-weight: 300;
  background: #242525;
  color: ${(props) => props.theme.colors.primary};
  width: 100%;
  line-height: 1;
  display: block;
  border-radius: 0;
  appearance: none;
  border: none;
  font-family: 'Proxima-Nova', arial;

  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.primary};
    color: black;
  }
`;
