import * as React from 'react';

interface IUseProgressiveImage {
  url: string;
  elementRef?: boolean;
  useLazyLoad?: boolean;
  visible?: boolean;
}

export const useProgressiveImage = ({
  url,
  elementRef,
  visible = true,
  useLazyLoad,
}: IUseProgressiveImage) => {
  if (typeof window !== 'undefined') {
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
      if (visible) {
        const img = document.createElement('img');

        const onLoad = () => {
          setLoaded(true);
        };
        const onError = () => {
          setLoaded(false);
        };

        img.addEventListener('load', onLoad);
        img.addEventListener('error', onError);

        if (url) {
          img.src = url;
        }

        return () => {
          img.removeEventListener('load', onLoad);
          img.removeEventListener('load', onError);
        };
      }
    }, [url, visible]);

    return { loaded };
  }
  return { loaded: false };
};
