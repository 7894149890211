import * as React from 'react';
import { FormError } from '../../components/containers/valuation/abtestingkoco/form/FormError';

type UseFormFieldType =
  | 'text'
  | 'tel'
  | 'email'
  | 'password'
  | 'checkbox'
  | 'textarea'
  | 'submit'
  | string;

type FilterType = 'none' | 'number' | 'zip';

type LabelPosType = 'left' | 'right';

export interface IUseFormField {
  type?: UseFormFieldType;
  name?: string;
  placeholder?: string;
  value?: string | number;
  checked?: boolean;
  label?: string;
  labelPos?: LabelPosType;
  labelAfter?: string;
  required?: boolean;
  error?: boolean;
  arrayName?: string;
  filter?: FilterType;
}
interface IUseForm {
  fields: Array<IUseFormField | null>;
  fieldConfigChanged?: boolean;
  submit: (fields: { [key: string]: any }) => void;
  onSubmit?: (cb: (hasErrors: boolean) => void) => void;
}

export const useForm = (args: IUseForm) => {
  const [fields, setFields] = React.useState<(IUseFormField | null)[]>(
    args.fields
  );

  const memoizedFields = React.useMemo(() => {
    return fields;
  }, [fields]);

  /* Update fields array if fieldConfigChanged is set to true */
  React.useEffect(() => {
    if (args.fieldConfigChanged) {
      setFields(args.fields);
    }
  }, [args.fieldConfigChanged]);

  const handleReset = () => {
    setFields(args.fields);
  };

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fieldValues = getFields(memoizedFields);
    if (typeof args.onSubmit === 'function') {
      args.onSubmit((hasErrors) => {
        validation((hasError) => {
          if (!hasError && !hasErrors) {
            args.submit(fieldValues);
          }
        });
      });
    } else {
      validation((hasError) => {
        if (!hasError) {
          args.submit(fieldValues);
        }
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const changedFields = memoizedFields.map((item) => {
      if (item && item.name === e.target.name) {
        if (item.type === 'checkbox') {
          item = Object.assign({}, item, { checked: !item.checked });
        } else {
          const value = e.target.value;
          switch (item?.filter) {
            case 'number':
              {
                if (!value?.length || /^[\d]+$/i.test(value))
                  item = Object.assign({}, item, { value });
              }
              break;
            case 'zip':
              {
                if (!value?.length || /^[\d]{1,4}$/i.test(value))
                  item = Object.assign({}, item, { value });
              }
              break;
            default:
              item = Object.assign({}, item, { value });
              break;
          }
        }
      }
      return item;
    });
    setFields(changedFields);
  };

  const getFields = (arr: Array<any>) => {
    let obj: any = {};
    if (arr && arr.length) {
      arr.map((item: any) => {
        if (item?.arrayName) {
          const list: any[] = obj[item?.arrayName] ?? [];
          switch (item?.type) {
            case 'checkbox':
              if (item?.checked) list.push(item?.value);
              break;
            default:
              if (item?.value) list.push(item.value);
              break;
          }

          if (list?.length)
            obj = Object.assign({}, obj, { [item.arrayName]: list });
        }

        obj = Object.assign(
          {},
          obj,
          item.type === 'checkbox'
            ? { [item.name]: item.checked }
            : { [item.name]: item.value }
        );
      });
    }
    return obj;
  };

  const validation = (cb: (hasError: boolean) => void) => {
    const errors: boolean[] = [];
    const validatedFields = memoizedFields.map((item: any) => {
      if (item && item.required) {
        item.error = false;
        if (item.value === '') {
          item.error = true;
          errors.push(true);
        }
        if (item.name === 'zip') {
          if (item.value.length === 4 && item.value.match(/[0-9]{4}/)) {
            item.error = false;
          } else {
            item.error = true;
            errors.push(true);
          }
        }
        if (item.type === 'tel') {
          if (
            item &&
            item.value &&
            item.value.length >= 8 &&
            !isNaN(item.value)
          ) {
            item.error = false;
          } else {
            item.error = true;

            errors.push(true);
          }
        }
        if (item.type === 'email') {
          const reg =
            /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
          const value = (item && item.value && item.value) || '';
          if (reg.test(value.toString())) {
            item.error = false;
          } else {
            item.error = true;
            errors.push(true);
          }
        }
      } else {
        item.error = false;
      }
      return item;
    });
    setFields(validatedFields);
    return cb(errors.length > 0);
  };
  return {
    fields: memoizedFields,
    getFields,
    handleSubmit,
    handleChange,
    handleReset
  };
};
