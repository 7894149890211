import * as React from 'react';
import styled, { css } from 'styled-components';
import MDSpinner from 'react-md-spinner';
import { Link } from 'react-router-dom';

type ButtonThemeType = 'primary' | 'secondary' | 'tertiary';
type ButtonColorTheme = 'gold' | 'gray' | 'coral';

type ButtonAsType = 'a' | 'link';

interface Props {
  colorTheme: ButtonThemeType;
}

interface IButton {
  type?: ButtonThemeType;
  as?: ButtonAsType;
  to?: string;
  href?: string;
  center?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
  colorTheme?: ButtonColorTheme;
  withIcon?: boolean;
  onClick?: (e: any) => void;
  target?: string;
  style?: any;
  className?: string;
}

export const Button: React.FC<IButton> = ({
  type,
  as,
  to,
  href,
  center,
  children,
  fullWidth,
  disabled,
  loading,
  colorTheme,
  withIcon,
  onClick,
  target,
  style,
  className
}) => {
  const buttonRef: any = React.useRef(null);
  if (as === 'link') {
    if (type === 'primary') {
      return (
        <StyledPrimaryButtonAsLink
          to={to}
          themetype={type}
          colortheme={colorTheme}
          fullwidth={fullWidth?.toString()}
          disabled={disabled}
          center={center?.toString()}
          style={style}
          className={className}
          target={target}
        >
          {loading ? (
            <span style={{ lineHeight: 'initial' }}>
              <MDSpinner size={20} singleColor="#000" />
            </span>
          ) : (
            children
          )}
        </StyledPrimaryButtonAsLink>
      );
    } else if (type === 'secondary') {
      return (
        <StyledSecondaryButtonAsLink
          to={to}
          themetype={type}
          fullwidth={fullWidth?.toString()}
          disabled={disabled}
          center={center?.toString()}
          style={style}
          className={className}
          target={target}
        >
          {children}
        </StyledSecondaryButtonAsLink>
      );
    } else if (type === 'tertiary') {
      return (
        <StyledTertiaryButtonAsLink
          to={to}
          themetype={type}
          fullwidth={fullWidth?.toString()}
          disabled={disabled}
          center={center?.toString()}
          style={style}
          className={className}
          target={target}
        >
          {children}
        </StyledTertiaryButtonAsLink>
      );
    }
  } else {
    if (type === 'primary') {
      return (
        <StyledButton
          as={as}
          href={href}
          themetype={type}
          colorTheme={colorTheme}
          fullwidth={fullWidth?.toString()}
          disabled={disabled}
          onClick={onClick}
          ref={buttonRef}
          center={center?.toString()}
          target={target}
          style={style}
          className={className}
        >
          {loading ? (
            <span style={{ lineHeight: 'initial' }}>
              <MDSpinner size={20} singleColor="#000" />
            </span>
          ) : (
            children
          )}
        </StyledButton>
      );
    } else if (type === 'secondary') {
      return (
        <StyledBorderButton
          as={as}
          href={href}
          fullwidth={fullWidth?.toString()}
          disabled={disabled}
          onClick={onClick}
          themetype={type}
          ref={buttonRef}
          center={center?.toString()}
          target={target}
          style={style}
          className={className}
        >
          <StyledSpan center={center?.toString()}>{children}</StyledSpan>
          {withIcon ? <ButtonIcon themetype={type} /> : null}
        </StyledBorderButton>
      );
    } else if (type === 'tertiary') {
      return (
        <StyledLinkButton
          as={as}
          href={href}
          fullwidth={fullWidth?.toString()}
          disabled={disabled}
          onClick={onClick}
          themetype={type}
          ref={buttonRef}
          center={center?.toString()}
          target={target}
          style={style}
          className={className}
        >
          {children}
        </StyledLinkButton>
      );
    }
  }
  return null;
};

const ButtonIcon: React.FC<any> = ({ colorTheme }) => {
  return (
    <StyledButtonIcon colorTheme={colorTheme}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="6.17064"
          y1="18.4964"
          x2="16.9964"
          y2="7.67065"
          strokeWidth="2"
        />
        <path d="M16.9965 19.012V7.67065H5.65515" strokeWidth="2" />
      </svg>
    </StyledButtonIcon>
  );
};

const StyledButtonIcon: any = styled.div`
  display: inline-block;
  margin-left: 10px;
  stroke: ${(props: any) => props.theme.buttons[props.themetype].colorIcon};
`;

const BaseButton = css<any>`
  display: block;
  transition: color 0.15s ease-in-out, background 0.15s ease-in-out;
  padding: 0 15px;
  height: 3rem;
  font-size: 16px;
  border: none;
  outline: none;
  font-weight: 400;
  color: ${(props: any) => props.theme.buttons[props.themetype].color};
  ${(props: any) => (props.fullwidth === 'true' ? `width: 100%;` : '')}
  appearance: none;
  -webkit-appearance: none;
  user-select: none;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  span {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }

  &.full-width {
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
`;

const PrimaryButton = css<any>`
  background: ${(props: any) =>
    props?.theme?.buttons[props.themetype]?.themes[
      props?.colorTheme || props?.colortheme
    ]?.background};
  color: ${(props) =>
    props?.theme?.buttons[props.themetype]?.themes[
      props?.colorTheme || props?.colortheme
    ]?.color};
  border-radius: 0;
  line-height: 3rem;

  ${({ center }) => {
    return center === 'true' ? 'text-align: center;' : '';
  }}
`;

const SecondaryButton = css<any>`
  transition: border 0.15s ease-in-out;
  display: flex;
  align-items: center;
  background: transparent;
  color: ${(props) => props?.theme?.buttons[props?.themetype]?.color};
  border: solid 2px
    ${(props) => props?.theme?.buttons[props?.themetype]?.borderColor};
  line-height: 3rem;

  ${({ center }) => {
    return center === 'true'
      ? 'justify-content: center;'
      : 'justify-content: space-between;';
  }}

  span {
    display: inline-block;
    line-height: 2.8125rem;
  }
`;

const TertiaryButton = css<any>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9375rem 0 0.625rem 0;
  background: none;
  color: ${(props: any) => props?.theme?.buttons[props?.themetype]?.color};
  border-bottom: solid 2px
    ${(props: any) => props?.theme?.buttons[props?.themetype]?.borderColor};

  ${({ center }) => {
    return center === 'true'
      ? 'justify-content: center;'
      : 'justify-content: space-between;';
  }}

  span {
    ${({ center }) => {
      return center === 'true' ? 'text-align: center;' : '';
    }}
  }
`;

const StyledButton: any = styled.button<any>`
  ${BaseButton}
  ${PrimaryButton}
`;

const StyledBorderButton: any = styled.button<any>`
  ${BaseButton}
  ${SecondaryButton}
`;

const StyledLinkButton: any = styled.a<any>`
  ${BaseButton}
  ${TertiaryButton}
  display: block;
  text-align: center;
`;

const StyledPrimaryButtonAsLink = styled((props) => <Link {...props} />)<any>`
  ${BaseButton}
  ${PrimaryButton}
  display: block;
`;

const StyledSecondaryButtonAsLink = styled((props) => <Link {...props} />)<any>`
  ${BaseButton}
  ${SecondaryButton}
`;

const StyledTertiaryButtonAsLink = styled((props) => <Link {...props} />)<any>`
  ${BaseButton}
  ${TertiaryButton}
`;

const StyledSpan = styled.span<any>`
  ${({ center }) => {
    return center === 'true' ? 'text-align: center;' : '';
  }}
`;
