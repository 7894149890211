import { darken } from 'polished';
import search from './search';

const colors = {
  primary: '#E7CFA7',
  secondary: '#252525',
  tertiary: '#1c1c1c',
  orange: '#e08752',
  burgundy: '#3F0814',
  burgundy_card: '#170308',
  burgyndy_shade: '#2A0B11',
  red: '#F10029',
  body: '#171719',
  bodyShade: darken('0.05', '#171719'),
  redwine: '#300A0F',
  coral: '#FF5A5A',
  neoncoral: '#FC3A3A',
  deepcoral: '#E2182F',
  licorice: '#171719',
  white: '#FFFFFF',
  gray: '#302d2e',
  champagne: '#E7CFA7',
  black: '#000'
};

const mediaSizes = {
  small: 600,
  medium: 800,
  large: 1140
};

const buttons = {
  primary: {
    background: colors.primary,
    borderColor: '#fff',
    colorIcon: colors.primary,
    hover: colors.tertiary,
    color: '#000',
    themes: {
      gold: {
        background: colors.primary,
        color: '#000'
      },
      gray: {
        background: '#242525',
        color: colors.primary
      },
      coral: {
        background: colors.coral,
        color: colors.white
      }
    }
  },
  secondary: {
    borderColor: colors.primary,
    hover: colors.tertiary,
    color: '#fff'
  },
  tertiary: {
    borderColor: colors.primary,
    hover: colors.tertiary,
    color: '#fff'
  }
};

const input = {
  font: {
    size: '1rem'
  },
  background: '#4b4b4b',
  color: 'rgb(255, 255, 255)',
  placeholder: '#b0b0b0',
  label: 'rgb(255, 255, 255)',
  labelAfter: 'rgb(100, 100, 100)',
  error: 'rgb(220, 40, 30)',
  padding: '15px'
};

const header = {
  search: {
    bg: 'rgb(30, 30, 30)'
  }
};

export const theme = Object.assign(
  {},
  { colors, buttons, mediaSizes, input, header, search }
);

type Theme = typeof theme;
type MediaSize = typeof mediaSizes;
declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
  export interface Media extends MediaSize {}
}
