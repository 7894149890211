import * as React from 'react';
import styled from 'styled-components';
import { IQuestion } from '../../../../utils/hooks/useQuiz';
import { Checkbox } from '../../../ui/form/Checkbox';

import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';

import { Button } from '../../../ui/button/Button';
import { ButtonGroup } from '../../../ui/button/ButtonGroup';
import { ButtonGridGroup } from './Index_v2';

export interface QuestionProps {
  loading: boolean;
  currentQuestion: number;
  totalQuestions: number;
  item: IQuestion;
  prev: () => void;
  next: () => void;
  done: () => void;
  lock: () => void;
  cancel: () => void;
  select: (answerId: number) => void;
};

const StyledQuestionWrapper = styled.div`
  padding: 20px;
  background: ${({ theme }) => theme.colors.burgundy_card};
`;

export const Question: React.FC<QuestionProps> = ({
  loading,
  currentQuestion,
  totalQuestions,
  item,
  prev,
  next,
  done,
  lock,
  cancel,
  select,
}) => {
  const hasSelectedCorrectAnswer = () => {
    return (
      item?.options?.filter((item) => item?.correct && item?.selected).length >
      0
    );
  };

  React.useEffect(() => {
    if (item?.selected && typeof lock === 'function') {
      lock();
    }
  }, [item?.selected]);

  if (loading) {
    return (
      <StyledQuestionWrapper>
        <Paragraph center={true}>Vennligst vent.. Laster spørsmål</Paragraph>
      </StyledQuestionWrapper>
    );
  }

  return (
    <StyledQuestionWrapper>
      <Heading tag="h2" center={true}>
        Spørsmål {currentQuestion} av {totalQuestions}
      </Heading>
      <Paragraph center={true}>{item?.question}</Paragraph>
      <CheckboxGroup>
        {item?.options.map((option) => {
          return (
            <AnswerRow key={option?.id}>
              <div className="answer-inside">
                {option?.letter ? (
                  <span className="letter">{option?.letter}</span>
                ) : null}
                <Checkbox
                  labelPos="right"
                  style={
                    item.locked
                      ? option?.correct && option?.selected
                        ? { border: 'solid 2px green' }
                        : option?.correct
                          ? { border: 'solid 2px green' }
                          : hasSelectedCorrectAnswer()
                            ? {}
                            : option?.selected && !option?.correct
                              ? { border: 'solid 2px red' }
                              : {}
                      : {}
                  }
                  label={option?.label}
                  checked={option?.selected}
                  onClick={
                    (!item?.locked
                      ? () => {
                        if (typeof select === 'function') {
                          select(option.id);
                        }
                      }
                      : () => { })
                  }
                />
              </div>
            </AnswerRow>
          );
        })}
      </CheckboxGroup>
      <ButtonGridGroup className={currentQuestion > 1 ? 'col-2' : ''}>
        {currentQuestion > 1 ? (
          <Button
            type="secondary"
            colorTheme="gold"
            onClick={() => {
              if (typeof prev === 'function') {
                prev();
              }
            }}
          >
            Tilbake
          </Button>
        ) : null}
        {currentQuestion < totalQuestions ? (
          <div style={item?.locked ? {} : { opacity: '0.5' }}>
            <Button
              type="primary"
              colorTheme="gold"
              onClick={() => {
                if (typeof next === 'function' && item.locked) {
                  next();
                }
              }}
            >
              Neste spørsmål
            </Button>
          </div>
        ) : null}
        {currentQuestion >= totalQuestions && item?.locked ? (
          <Button
            type="primary"
            colorTheme="gold"
            onClick={() => {
              if (typeof done === 'function') {
                done();
              }
            }}
          >
            Resultat
          </Button>
        ) : null}
      </ButtonGridGroup>
      <ButtonGridGroup style={{ marginTop: '40px' }}>
        <Button
          type="tertiary"
          colorTheme="gold"
          onClick={() => {
            if (typeof cancel === 'function') {
              cancel();
            }
          }}
        >
          Avbryt
        </Button>
      </ButtonGridGroup>
    </StyledQuestionWrapper>
  );
};

const CheckboxGroup = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, max-content);
  justify-content: center;
  padding: 20px 0 30px 0;
  width: 100%;
`;

const AnswerRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: start;
  margin-bottom: 10px;
  width: 100%;

  div.answer-inside {
    display: flex;
    width: 100%;
  }

  .letter {
    margin-right: 20px;
    color: rgb(255, 255, 255);
    font-weight: 600;
  }
`;