export const fonts = `
  @font-face {
    font-family: 'DomaineDispCondRegular';
    src: url('https://cdn.reeltime.no/pm_assets/fonts/DomaineDisplayCondensed-Regular.otf');
    font-weight: 300;
  }
  @font-face {
    font-family: 'Geograph-Regular';
    src: url('https://cdn.reeltime.no/pm_assets/fonts/Geograph-Regular.otf'),
        format('opentype');
    font-weight: normal;
  }
  @font-face {
    font-family: 'Geograph-Bold';
    src: url('https://cdn.reeltime.no/pm_assets/fonts/Geograph-Bold.otf'),
        format('opentype');
    font-weight: bold;
  }
  @font-face {
    font-family: 'DomaineText-Regular';
    src: url('https://cdn.reeltime.no/pm_assets/fonts/DomaineText-Regular.otf'),
        format('opentype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Avenir';
    src: url('https://cdn.reeltime.no/pm_assets/fonts/Avenir.ttc'),
        format('opentype');
  }
  @font-face {
    font-family: 'Kondo';
    src: url('https://cdn.reeltime.no/pm_assets/fonts/Kondo-Regular.otf'),
        format('opentype');
  }

`;
