import styled from 'styled-components';
import { animated } from 'react-spring';

export const Section = styled(animated.div)`
  padding: 3.125rem 0;

  &.center {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  &.form-section {
    max-width: 550px;
    margin: 0 auto;
  }

  &.no-padding-top {
    padding: 0 0 3.125rem 0;
  }

  &.office {
    padding: 0;
  }
  &.brokerlist {
    @media all and (max-width: 600px) {
      padding: 0 0 3.125rem;
    }
  }
`;
