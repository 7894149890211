import * as React from 'react';
import { defaultContext } from '../../__config__/context';

import { routeConfig, RouteConfig } from '../../__config__/routes';
import { createDiggerSession } from '../tracking/digger';

interface ISiteContext {
  header: any;
  digger: any;
  routeConfig: RouteConfig | null;
  contextData: any;
  setContextData: any;
}

export const SiteContext = React.createContext<ISiteContext>({
  header: null,
  digger: null,
  routeConfig: null,
  contextData: null,
  setContextData: () => {}
});

export const SiteContextProvider: React.FC = ({ children }) => {
  const [contextData, setContextData] = React.useState({});
  const [digger, setDiggerState] = React.useState(null);

  /*
    Setting digger session on page render
  */

  React.useEffect(() => {
    const diggerInstance = createDiggerSession();
    setDiggerState(diggerInstance);
  }, []);

  return (
    <SiteContext.Provider
      value={{
        digger,
        routeConfig,
        contextData,
        setContextData,
        ...defaultContext()
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
