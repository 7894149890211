import * as React from 'react';
import { useSpring } from 'react-spring';

interface IUseTransition {
  loaded?: boolean;
  slideDown?: boolean;
  delay?: number;
  immidiate?: boolean;
}

interface IUseTransitionReturn {
  fadeIn: any;
}

export const useFadeTransition = ({
  loaded,
  slideDown,
  delay,
  immidiate,
}: IUseTransition): IUseTransitionReturn => {
  let obj: any = {};
  if (slideDown) {
    obj = {
      delay: delay ? delay : 0,
      from: { opacity: 0, transform: 'translate3d(0, -20px, 0)' },
      to: {
        opacity: loaded || immidiate || delay ? 1 : 0,
        transform:
          loaded || immidiate || delay
            ? 'translate3d(0, 0, 0)'
            : 'translate3d(0, -20px, 0)',
      },
    };
  } else {
    obj = {
      delay: delay ? delay : 0,
      from: { opacity: 0 },
      to: {
        opacity: loaded || immidiate || delay ? 1 : 0,
      },
    };
  }

  const fadeIn = useSpring(obj);

  return { fadeIn };
};
