import * as React from 'react';
import styled from 'styled-components';

type CheckboxLabelPosType = 'left' | 'right';

interface ICheckbox {
  type?: string;
  placeholder?: string;
  className?: string;
  id?: string;
  name?: string;
  label?: string;
  labelPos?: CheckboxLabelPosType;
  subText?: string;
  checked?: boolean | null;
  error?: boolean;
  style?: any;
  count?: number;
  onClick?: (e: any) => void;
  onChange?: (e: any) => void;
}

export const Checkbox: React.FC<ICheckbox> = ({
  className,
  id,
  name,
  label,
  labelPos = 'left',
  error,
  subText,
  checked,
  style,
  count,
  onChange,
  onClick
}) => {
  const checkboxInputRef: any = React.useRef(null);

  const CheckboxLabel = () => {
    return (
      <StyledCheckboxLabel
        pos={labelPos}
        className={error ? 'error checkbox-label' : 'checkbox-label'}
      >
        <span>
          {label}
          {typeof count === 'number' ? (
            <span className="match-count">({count})</span>
          ) : null}
        </span>
        {subText ? <span className="sub-label-text">{subText}</span> : null}
      </StyledCheckboxLabel>
    );
  };

  return (
    <StyledCheckboxWrapper
      className={className}
      onClick={() => {
        if (checkboxInputRef && checkboxInputRef.current) {
          checkboxInputRef.current.click();
        }
      }}
    >
      {labelPos === 'left' ? <CheckboxLabel /> : null}
      <StyledCheckbox
        tabIndex={0}
        id={id ? id : ''}
        className={error ? 'error' : ''}
        style={style}
      >
        {checked ? <Checkmark /> : null}
      </StyledCheckbox>
      {labelPos === 'right' ? <CheckboxLabel /> : null}
      <input
        ref={checkboxInputRef}
        type="checkbox"
        name={name}
        checked={checked || false}
        onChange={(e) => {
          if (typeof onChange === 'function') {
            onChange(e);
          }
        }}
        onClick={onClick}
      />
    </StyledCheckboxWrapper>
  );
};

const Checkmark: React.FC = () => {
  return (
    <StyledCheckmark>
      <svg
        version="1.1"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m5.0586 53.211 9.7383-12.508 26.016 21.457 42.754-46.883 11.84 10.613-52.934 58.473z"></path>
      </svg>
    </StyledCheckmark>
  );
};

const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  user-select: none;

  input {
    visibility: hidden;
    width: 1;
    height: 1;
  }

  &:hover {
    cursor: pointer;
  }
`;

const StyledCheckbox = styled.div`
  position: relative;
  width: 23px;
  height: 23px;
  background: ${(props: any) => props.theme.input.background};
  border: solid 2px transparent;
  flex: 0 0 23px;

  &.error {
    border: solid 2px ${(props) => props.theme.input.error};
  }
`;

const StyledCheckmark = styled.div`
  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 15px;
    height: 15px;
    margin: auto;
    path {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;

const StyledCheckboxLabel: any = styled.div`
  font-size: 1rem;
  color: white;
  ${(props: any) => (props.pos === 'left' ? 'margin-right: 20px;' : '')}
  ${(props: any) => (props.pos === 'right' ? 'margin-left: 20px;' : '')}

  &.error {
    color: ${(props) => props.theme.input.error};
  }

  span {
    display: flex;
    align-items: center;
    &.sub-label-text {
      font-size: 12px;
    }

    .match-count {
      display: inline-block;
      margin-left: 0.5em;
      font-size: 0.8em;
      color: rgb(100, 100, 100);
    }
  }
`;
