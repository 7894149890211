import * as React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

type IButtonType = 'a' | 'link';

interface IButtonLink {
  as: IButtonType;
  to?: string;
  href?: string;
  className?: string;
}

export const ButtonLink: React.FC<IButtonLink> = ({
  as,
  to,
  href,
  className,
  children
}) => {
  if (as === 'a') {
    return (
      <StyledButtonLink as="a" href={href} className={className}>
        {children}
      </StyledButtonLink>
    );
  }
  if (as === 'link') {
    return (
      <StyledButtonLink to={to} className={className}>
        {children}
      </StyledButtonLink>
    );
  }
  return null;
};

const BaseButtonLink = css`
  display: flex;
  align-items: center;
  padding: 0 1.25rem;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
`;

const StyledButtonLink = styled((props) => <Link {...props} />)`
  ${BaseButtonLink}
`;
