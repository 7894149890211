import * as React from 'react';
import styled, { css } from 'styled-components';

type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type ColorType = 'white' | 'black' | 'champagne';

interface IHeading {
  className?: string;
  tag: HeadingType;
  center?: boolean;
  color?: ColorType;
  children: any;
  style?: any;
}

export const Heading: React.FC<IHeading> = (props) => {
  let style = { ...props.style };
  if (props.color) {
    style = { ...style, color: props.color };
  }

  switch (props.tag) {
    case 'h1':
      return (
        <StyledHeadingH1
          center={props.center}
          className={props.className}
          style={style}
        >
          {props.children}
        </StyledHeadingH1>
      );
    case 'h2':
      return (
        <StyledHeadingH2
          center={props.center}
          className={props.className}
          style={style}
        >
          {props.children}
        </StyledHeadingH2>
      );
    case 'h3':
      return (
        <StyledHeadingH3
          center={props.center}
          className={props.className}
          style={style}
        >
          {props.children}
        </StyledHeadingH3>
      );
    case 'h4':
      return (
        <StyledHeadingH4
          center={props.center}
          className={props.className}
          style={style}
        >
          {props.children}
        </StyledHeadingH4>
      );
    case 'h5':
      return (
        <StyledHeadingH5
          center={props.center}
          className={props.className}
          style={style}
        >
          {props.children}
        </StyledHeadingH5>
      );
    case 'h6':
      return (
        <StyledHeadingH6
          center={props.center}
          className={props.className}
          style={style}
        >
          {props.children}
        </StyledHeadingH6>
      );
    default:
      return null;
  }
};

interface Props {
  center?: boolean;
}

const BaseHeading = css`
  ${(props: any) => (props.center ? 'text-align: center;' : '')};
  ${(props: any) => (props.center ? 'margin: 0 auto 20px auto;' : '')}

  line-height: 1.2;

  &.no-margin {
    margin: 0;
  }

  &.underline {
    display: flex;
    flex-flow: column;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 25px;

    &::after {
      position: absolute;
      content: '';
      width: 40px;
      height: 5px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background: ${({ theme }) => theme.colors.coral};
    }

    &.underline-align-left {
      &::after {
        width: 100px;
        left: 0;
        right: auto;
        margin: unset;
      }
    }
  }
`;

const marginTop = css`
  margin-top: 25px;
`;

const StyledHeadingH1 = styled.h1<Props>`
  ${BaseHeading}
`;

const StyledHeadingH2 = styled.h2<Props>`
  ${BaseHeading}
  ${marginTop}
`;

const StyledHeadingH3 = styled.h3<Props>`
  ${BaseHeading}
  ${marginTop}
`;

const StyledHeadingH4 = styled.h4<Props>`
  ${BaseHeading}
  ${marginTop}
`;

const StyledHeadingH5 = styled.h5<Props>`
  ${BaseHeading}
`;

const StyledHeadingH6 = styled.h6<Props>`
  ${BaseHeading}
  ${marginTop}
`;
