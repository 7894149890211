import { CSSObject, css } from 'styled-components';

export const mediaQueries = {
  tabletPortrait: (args: TemplateStringsArray | CSSObject) => css`
    @media (min-width: 768px) and (max-width: 1024px) {
      ${css(args)};
    }
  `,
  tablet: (args: TemplateStringsArray | CSSObject) => css`
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      ${css(args)};
    }
  `,
  navbar: (args: TemplateStringsArray | CSSObject) => css`
    @media (min-width: 1080px) {
      ${css(args)};
    }
  `,
  desktop: (args: TemplateStringsArray | CSSObject) => css`
    @media (min-width: 1025px) {
      ${css(args)};
    }
  `,
  desktopMd: (args: TemplateStringsArray | CSSObject) => css`
    @media (min-width: 1140px) {
      ${css(args)};
    }
  `,
  desktopLg: (args: TemplateStringsArray | CSSObject) => css`
    @media (min-width: 1280px) {
      ${css(args)};
    }
  `,
  desktopXl: (args: TemplateStringsArray | CSSObject) => css`
    @media (min-width: 1440px) {
      ${css(args)};
    }
  `,
  mobile: (args: TemplateStringsArray | CSSObject) => css`
    @media (min-width: 250px) and (max-width: 440px) {
      ${css(args)};
    }
  `
};
