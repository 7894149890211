import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { useProgressiveImage } from '../../utils/hooks/useProgressiveImage';

interface IBackgroundLoad {
  className?: string;
  url: string;
  height?: string;
  opacity?: number;
  position?: string;
  fixed?: boolean;
  showOnMobile?: boolean;
  hideGradient?: boolean;
  style?: any;
}

interface IProps {
  url?: string;
  height?: string;
  position?: string;
  fixed?: boolean;
  opacity?: number;
  loaded: boolean;
}

export const BackgroundLoad: React.FC<IBackgroundLoad> = React.memo(
  ({
    className,
    url,
    height,
    opacity,
    position,
    fixed,
    showOnMobile,
    hideGradient,
    style
  }) => {
    const { loaded } = useProgressiveImage({
      url
    });

    return (
      <StyledBackgroundWrapper
        style={style}
        className={showOnMobile ? 'show-on-mobile' : ''}
      >
        <StyledBackground
          className={className}
          height={height}
          url={url}
          opacity={opacity}
          position={position}
          fixed={fixed}
          loaded={loaded}
        >
          {hideGradient ? null : (
            <StyledBackgroundGradient height={height} loaded={loaded} />
          )}
        </StyledBackground>
      </StyledBackgroundWrapper>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.url === nextProps.url) {
      return true;
    }
    return false;
  }
);

const StyledBackgroundWrapper = styled.div<any>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  overflow: hidden;

  img {
    position: relative;
    width: 1px;
    height: 1px;
  }

  @media all and (max-width: 600px) {
    display: none;

    &.show-on-mobile {
      display: flex;
    }
  }
`;

const StyledBackground = styled.div<IProps>`
  transition: opacity 0.3s ease-out;
  width: 100%;
  height: ${(props) => (props.height ? props.height : '100vh')};
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props) => `url(${props.url}) no-repeat`};
  background-size: cover;
  background-position: ${(props) =>
    props.position ? props.position : 'center'};
  z-index: -1;
  opacity: ${(props) =>
    props.loaded ? (props.opacity ? props.opacity : 0.2) : 0};

  ${(props) => (props.fixed ? 'background-attachment: fixed;' : '')}
`;

const StyledBackgroundGradient = styled.div<IProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${(props) => (props.height ? props.height : '50vh')};
  background: ${({ theme }) =>
    `linear-gradient(transparent, ${theme.colors.body})`};
  opacity: ${(props) => (props.loaded ? 1 : 0)};
`;
