import * as React from 'react';

export const defaultContext = () => {
  const [forceBackground, setForceBackground] = React.useState(false);
  return {
    header: {
      forceBackground,
      setForceBackground
    }
  };
};
