import styled from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled(animated.div)`
  width: 100%;
  max-width: 60rem;
  padding: 0 2rem;
  margin: 0 auto;

  table rt th {
    color: white;
  }
  &.padding-top {
    padding-top: 6.25rem;
  }
  &.padding-bottom {
    padding-bottom: 3.125rem;
  }

  @media all and (max-width: ${(props) => props.theme.mediaSizes.small}px) {
    &.padding-top {
      padding-top: 3.125rem;
    }
  }
`;
