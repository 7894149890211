import * as React from 'react';
import styled from 'styled-components';

interface IFormError {
  message: string;
}

export const FormError: React.FC<IFormError> = ({ message }) => {
  return <StyledFormError>{message}</StyledFormError>;
};

const StyledFormError = styled.div`
  width: 100%;
  padding: 0.9375rem;
  margin-bottom: 0.625rem;
  background: ${(props) => props.theme.input.error};
  font-size: 1rem;
  color: #fff;
`;
