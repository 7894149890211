import * as React from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import { useFormTransition } from '../../../../utils/hooks/useFormTransition';
import { SiteContext } from '../../../../utils/context/SiteContext';
import { IQuestion } from '../../../../utils/hooks/useQuiz';

import { useForm } from '../../../../utils/hooks/useForm';
import { Form, FormRow } from '../../../ui/form/Form';
import { FormError } from '../../../ui/form/FormError';
import { Button } from '../../../ui/button/Button';
import { ButtonGroup } from '../../../ui/button/ButtonGroup';
import { ButtonLink } from '../../../ui/link/ButtonLink';
import { Input } from '../../../ui/form/Input';

//GraphQl
import { createLeadMutation } from '../../../../../client/__graphql__/mutations';
import { useMutation } from '@apollo/client';
import { ILeadInput, ILeadResponse } from '../../../../__types__/global';
import { useLocation } from 'react-router';

export const FormSection: React.FC<{
  questions: Array<IQuestion>;
  mailType: number;
  formZipcode?: boolean;
  privacyPolicy?: string;
  post: () => void;
}> = ({ questions, mailType, formZipcode, privacyPolicy, post }) => {
  const { routeConfig }: any = React.useContext(SiteContext);
  const [posted, setPosted] = React.useState(false);
  const location = useLocation();
  const shouldShowCommentField = location.pathname.includes('/quiz/oslo');

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const MAIL_MUTATION = createLeadMutation({
    mail: {
      response: `{
              success
            }`
    }
  });

  const [mail, { loading, error }] = useMutation<ILeadResponse, ILeadInput>(
    MAIL_MUTATION,
    {
      onCompleted: ({ lead }) => {
        if (lead.mail?.success) {
          post();
        }
      }
    }
  );

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Eks. Ola Nordmann',
        value: '',
        label: 'Navn *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      ...(shouldShowCommentField
        ? [
            {
              type: 'text',
              name: 'comment',
              placeholder:
                'Hvis du vinner, hvilket motiv ønsker du? Åslo, Osjlo eller Ozlo',
              value: '',
              label: 'Kommentar *',
              labelAfter: '(Valgfritt)',
              required: true
            }
          ]
        : []),
      ...(formZipcode
        ? [
            {
              type: 'text',
              name: 'zip',
              placeholder: 'Eks. 0000',
              value: '',
              label: 'Postnummer *',
              required: true
            }
          ]
        : [])
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);
      let correct = 0;
      questions?.map((item) => {
        item.options.filter((item) => {
          if (item?.selected && item?.correct) {
            correct += 1;
          }
        });
      });
      const comment = `${correct} riktig av ${questions.length} mulige.`;
      mail({
        variables: {
          input: {
            ...preparedFields,
            referer: window.location.href,
            leadType: 'EPOST',
            mailType: mailType,
            comment: comment,
            zip: preparedFields?.zip ? preparedFields?.zip : '0000',
            json: {
              prize: preparedFields?.comment
            }
          }
        }
      });
    }
  });

  return (
    <>
      <FormWrapper style={fadeOut}>
        <Form
          onSubmit={handleSubmit}
          noValidate
          style={{ paddingBottom: '50px' }}
        >
          {error &&
            error.graphQLErrors.map((error, index) => (
              <FormError key={`error_${index}`} message={error.message} />
            ))}
          {fields.map((item: any) => {
            if (item) {
              return (
                <FormRow key={`field_${item.name}`}>
                  <Input
                    type={item.type}
                    name={item.name}
                    label={item.label}
                    labelAfter={item.labelAfter}
                    placeholder={item.placeholder}
                    value={item.value}
                    onChange={handleChange}
                    error={item.error}
                  />
                </FormRow>
              );
            }
          })}
          <ButtonGroup>
            <Button
              type="primary"
              colorTheme="gold"
              disabled={loading}
              loading={loading}
            >
              Send
            </Button>
            <ButtonLink
              as="a"
              href={routeConfig.personvern.url}
              className="privacy-link-mobile"
            >
              Personvernpolicy
            </ButtonLink>
          </ButtonGroup>
          {privacyPolicy && privacyPolicy?.trim()?.length > 0 ? (
            <>
              <p
                style={{ fontSize: '14px' }}
                dangerouslySetInnerHTML={{ __html: privacyPolicy }}
              />
            </>
          ) : (
            <>
              <p style={{ fontSize: '14px' }}>
                <b>*</b> Alle påmeldte gir sitt samtykke til å bli kontaktet av
                PrivatMegleren per telefon eller e-post.
              </p>
            </>
          )}
        </Form>
      </FormWrapper>
    </>
  );
};

const FormWrapper = styled(animated.div)``;
