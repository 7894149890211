import { gql } from '@apollo/client';

export const QUIZ_PAGE = gql`
  query quiz($input: QuizInput!) {
    getQuizPage(input: $input) {
      id
      mailType
      active
      seo {
        title
        content
        image
      }
      pageInit {
        title
        ingress
        content
        image
      }
      pageThankyou {
        title
        ingress
        content
        image
      }
      pageEnd {
        title
        ingress
        content
        image
      }
      rules
      formZipcode
      privacyPolicy
    }
  }
`;

export const QUIZ_QUESTIONS = gql`
  query questions($input: QuizQuestionsInput!) {
    getQuizQuestions(input: $input) {
      text
      options {
        id
        text
        letter
        pos
        isCorrect
      }
    }
  }
`;
