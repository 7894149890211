import * as React from 'react';
import styled from 'styled-components';

interface IParagraph {
  className?: string;
  center?: boolean;
  fontSize?: string;
  style?: any;
  children: any;
  html?: string;
}

export const Paragraph: React.FC<IParagraph> = (props) => {
  return (
    <StyledParagraph
      center={props.center}
      fontSize={props.fontSize}
      className={props.className}
      style={props.style}
      html={props.html}
    >
      {props.children}
    </StyledParagraph>
  );
};

const StyledParagraph = styled.p<IParagraph>`
  ${(props) => (props.center ? 'text-align: center;' : '')}
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize};` : '')}
  line-height: 1.4;
  &.form-consent-text {
    font-size: 0.875rem;
  }
`;
