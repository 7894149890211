export const type = {
  size1: {
    fontSize: `3.38rem`,
    lineHeight: `3rem`,
    margin: `0px 0px 1.5rem 0px`
  },
  size2: {
    fontSize: `2.25rem`,
    lineHeight: `3rem`,
    margin: `0px 0px 1.5rem 0px`
  },
  size3: {
    fontSize: `1.5rem`,
    lineHeight: `2.25rem`,
    margin: `0px 0px 2.25rem 0px`
  },
  size4: {
    fontSize: `1rem`,
    lineHeight: `1.5rem`,
    margin: `0px 0px 1.5rem 0px`
  },
  size5: {
    fontSize: `0.82rem`,
    lineHeight: `0.75rem`,
    margin: `0px 0px 0.75rem 0px`
  },
  size6: {
    fontSize: `0.67rem`,
    lineHeight: `0.75rem`,
    margin: `0px 0px 0.75rem 0px`
  },
  filter: {
    fontSize: `0.9rem`
  }
};
