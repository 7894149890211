import { stat } from 'fs';
import * as React from 'react';

export interface IAnswerOption {
  id: number;
  letter?: string;
  label: string;
  selected: boolean;
  correct: boolean;
}

export interface IQuestion {
  id: number;
  question: string;
  multiple?: boolean;
  locked: boolean;
  selected: boolean;
  options: Array<IAnswerOption>;
}

interface IUseQuiz {
  storeName: string;
  questions: Array<IQuestion>;
  onReset: () => void;
}

interface IInitalState {
  isStarted: boolean;
  isDone: boolean;
  currentNumber: number;
  correctNumber: number;
  questions: Array<IQuestion>;
}

export const useQuiz = (args: IUseQuiz) => {
  const [isStarted, setStarted] = React.useState(false);
  const [isDone, setDone] = React.useState(false);
  const [isPosted, setPosted] = React.useState(false);

  const [currentNumber, setCurrentNumber] = React.useState<number>(0);
  const [correctNumber, setCorrectNumber] = React.useState<number>(0);
  const [questions, setQuestions] = React.useState<Array<IQuestion>>([]);

  const start = () => {
    setStarted(true);
  };

  const prev = () => {
    const prevQuestionIndex = currentNumber - 1;
    setCurrentNumber(prevQuestionIndex);
  };

  const next = () => {
    const nextQuestionIndex = currentNumber + 1;
    setCurrentNumber(nextQuestionIndex);
  };

  const done = () => {
    setDone(true);
  };

  const posted = () => {
    setPosted(true);
  };

  const lock = () => {
    setQuestions((prev) => [
      ...prev.filter((question) => {
        if (question.id === questions[currentNumber].id) {
          question.locked = true;
          return question;
        } else {
          return question;
        }
      }),
    ]);
  };

  const selectAnswer = (answerId: number) => {
    setQuestions((prev) => [
      ...prev.filter((question) => {
        if (question.id === questions[currentNumber].id) {
          question.options.filter((option) =>
            option.id === answerId
              ? option.selected
                ? (option.selected = false)
                : (option.selected = true)
              : question.multiple
              ? option
              : (option.selected = false)
          );

          if (question.options.filter((item) => item.selected).length > 0) {
            question.selected = true;
          } else {
            question.selected = false;
          }

          return question;
        } else {
          return question;
        }
      }),
    ]);
  };

  const cancel = () => {
    setStarted(false);
    setDone(false);
    setCurrentNumber(0);
    setCorrectNumber(0);
    if (typeof args.onReset === 'function') {
      args.onReset();
    }
  };

  React.useEffect(() => {
    let correct = 0;
    questions.map((item) => {
      item.options.map((item) => {
        if (item.correct && item.selected) {
          correct += 1;
        }
      });
    });
    setCorrectNumber(correct);
  }, [isDone]);

  React.useEffect(() => {
    setQuestions(args.questions);
  }, [, args.questions]);

  return {
    isStarted,
    isPosted,
    isDone,
    start,
    prev,
    lock,
    next,
    done,
    posted,
    cancel,
    selectAnswer,
    correctNumber,
    questions: questions,
    totalQuestions: questions.length || 0,
    currentNumber: currentNumber + 1,
    currentQuestion: questions[currentNumber],
  };
};
