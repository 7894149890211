import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { ClipLoader } from 'react-spinners';

interface IPageLoaderFallback extends React.HTMLAttributes<HTMLElement> {}

const StyledPageLoaderFallback = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
`;

export const PageLoaderFallback: React.FC<IPageLoaderFallback> = () => {
  const theme = useTheme();
  return (
    <StyledPageLoaderFallback>
      <ClipLoader color={theme.colors.primary} size="35px" />
    </StyledPageLoaderFallback>
  );
};
