import { useLocation } from "react-router"

export const useMenuHide = () => {
    const location = useLocation();

    if(location?.search) {
        return location?.search?.toLowerCase()?.indexOf('menu=hide') > -1;
    }

    return false;
}