import { useSpring } from 'react-spring';

interface IUseFormTransition {
  posted: boolean;
}

interface IUseFormTransitionReturn {
  fadeOut: any;
  fadeIn: any;
}

export const useFormTransition = ({
  posted,
}: IUseFormTransition): IUseFormTransitionReturn => {
  const fadeOut = useSpring({
    from: { opacity: 1, display: 'block' },
    to: { opacity: posted ? 0 : 1, display: posted ? 'none' : 'block' },
  });

  const fadeIn = useSpring({
    from: { opacity: 0, display: 'none' },
    to: { opacity: posted ? 1 : 0, display: posted ? 'block' : 'none' },
  });
  return { fadeOut: fadeOut, fadeIn: fadeIn };
};
