import { ENV_CONFIG } from '../../__config__/env';

let Digger: any = null;
if (typeof window !== 'undefined') {
  Digger = require('@pm/digger-js/lib');
}

export const createDiggerSession = () => {
  let digger = null;
  try {
    if (typeof window !== 'undefined' && typeof Digger === 'function') {
      digger = new Digger({
        apiKey: ENV_CONFIG.DIGGER_API_KEY,
        debug: false, //ENV_CONFIG.NODE_ENV !== 'production',
      });
      digger
        .pageView({
          referrer: window.location.href,
          setSession: true,
        })
        .then((res: any) => {
          console.log(res);
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  } catch (err) {
    console.error('DIGGER', err);
  }
  return digger;
};

const filterFields = (form: any) => {
  let fields: any = {};
  let keys: any = Object.keys(form);
  for (let key of keys) {
    if (key !== '__typename') {
      fields[key] = form[key];
    }
  }
  return fields;
};

export const getDiggerData = (externalData: any) => {
  try {
    if (typeof window !== 'undefined') {
      const data = localStorage.getItem('pm_digger_session');
      if (data) {
        let parsed = JSON.parse(data);
        let formFields = filterFields(externalData);
        let obj = {
          session_id: parsed.params?.session_id,
          referrer: parsed.params?.referrer,
          tracking_code: parsed.params?.tracking_code,
          user_agent: parsed.params?.user_agent,
          path: parsed.params?.path,
          ...formFields,
        };
        return obj;
      }
    }
  } catch (err) {
    console.error(err);
  }
};
