import { gql } from '@apollo/client';

export const VALUATION_MUTATION = gql`
  mutation Valuation($input: ValuationInput!) {
    valuation(input: $input) {
      success
      data {
        id
        firstname
        lastname
        address
        zip
        email
        phone
        comment
      }
    }
  }
`;

export const SOLDPRICE_MUTATION = gql`
  mutation SoldPrice($input: LeadInput!) {
    lead(input: $input) {
      soldprice {
        success
      }
    }
  }
`;

export const SOLDPRICE_OSLO_MUTATION = gql`
  mutation SoldPriceOslo($input: LeadInput!, $soldprice: SoldPriceInput) {
    lead(input: $input) {
      soldprice(input: $soldprice) {
        success
      }
    }
  }
`;

export const CONTACT_MUTATION = gql`
  mutation contact($input: LeadInput!) {
    lead(input: $input) {
      contact {
        success
      }
    }
  }
`;

export const INTERESTED_MUTATION = gql`
  mutation Interested($input: LeadInput!) {
    lead(input: $input) {
      interested {
        success
        data {
          ids {
            type
            id
          }
        }
      }
    }
  }
`;

export const SAVE_DIGGER_ID_MUTATION = gql`
  mutation SaveDigger($input: SaveDiggerInput!) {
    saveDiggerId(input: $input) {
      success
    }
  }
`;

export const BOLIGSOK_MUTATION = gql`
  mutation search($input: BS_BoligsokFormInput) {
    boligsokform(boligsokFormInput: $input) {
      response
    }
  }
`;
export const BOLIGSOK_UPDATE_MUTATION = gql`
  mutation updateSearch($input: BS_BoligsokFormUpdateInput) {
    updateBoligsokform(boligsokFormInput: $input) {
      response
    }
  }
`;

export const BOLIGSOK_UNSUBSCRIBE_MUTATION = gql`
  mutation UnsubscribeFromPropertySearch($hash: String) {
    unsubscribe(hash: $hash) {
      response
    }
  }
`;

export const BOLIGSOK_REGISTRER_SAMTYKKER_MUTATION = gql`
  mutation RegistrerBrukerSamtykke($input: BrukerSamtykker!) {
    registrerBrukerSamtykke(input: $input) {
      success
    }
  }
`;

declare enum ResolverType {
  mail = 'mail',
  valuation = 'valuation',
  interested = 'interested',
  contact = 'contact',
  financing = 'financing'
}

interface IResolverOpts {
  response?: string;
}

type IArgs = {
  [index in ResolverType]?: IResolverOpts;
};

export const createLeadMutation = (args: IArgs) => {
  let resolvers: string = '';
  const keys: any = Object.keys(args);
  let key: keyof typeof ResolverType;
  for (key of keys) {
    resolvers += `${key} ${args[key]?.response}`;
  }
  if (resolvers === '') {
    throw new Error('Lead must have some resolvers defined');
  }
  return gql`
    mutation Lead($input: LeadInput!) {
      lead(input: $input) {
        ${resolvers.replace(/\u00A0/, '')}
      }
    }
  `;
};
