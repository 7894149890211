interface IEnvConfig {
  PORT: string;
  NODE_ENV: string;
  PM_GRAPH: string;
  RT_CLUSTER_GRAPH: string;
  DIGGER_API_KEY: string;
  BRAND_ID: string;
  POSTHOG_API_KEY: string;
}

export const ENV_CONFIG: IEnvConfig = {
  PORT: process.env.PORT || '',
  NODE_ENV: process.env.NODE_ENV || '',
  PM_GRAPH: process.env.PM_GRAPH || '',
  RT_CLUSTER_GRAPH: process.env.RT_CLUSTER_GRAPH || '',
  DIGGER_API_KEY: process.env.DIGGER_API_KEY || '',
  BRAND_ID: process.env.BRAND_ID || ``,
  POSTHOG_API_KEY: process.env.POSTHOG_API_KEY || ``
};
